import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { parserList } from "../AddEmailParser/parserList";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  GET_TEMPLATES,
  GET_PARSERTYPES,
  MUTATE_EMAILPARSERS,
  GET_MAPPING,
  UPDATE_EMAIL_PARSER_CONFIG,
  GET_PARSER_TEMPLATE,
} from "./query";
import { ThemeContext } from "src/helpers/themeContext";
import client from "../../apollo";
import { add, template, toNumber } from "lodash";
import { useLazyQuery, useMutation } from "@apollo/client";
import CustomMappingMenu from "../AddEmailParser/CustomMappingMenu";
import { toast } from "react-hot-toast";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  data: any;
  refresh: any;
}

const EditEmailParser = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };

  const Theme = useContext(ThemeContext);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [selectedParser, setSelectedParser] = useState(-1);
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [parsers, setParsers] = useState<any>([]);
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [defaultForwardingValue, setDefaultForwardingValue] = useState(1);
  const [editCustomMappingsModal, setEditCustomMappingsModal] = useState(false);
  const [saved, setSaved] = useState(false);
  const [mappingData, setMappingData] = useState<any>(null);
  const [editClicked, setEditClicked] = useState(false);
  const [templateVariableCount, setTemplateVariableCount] = useState(0);
  const [templateText, setTemplateText] = useState("");
  const [customMappingOptions, setCustomMappingOptions] = useState<any>();
  const [addedCustomMaps, setAddedCustomMaps] = useState<any>([]);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [doneClicked, setDoneClicked] = useState(false);
  const [selectedForwardOption, setSelectedForwardOption] = useState<any>(1);
  const [selectedForwardingEmail, setSelectedForwardingEmail] =
    useState<any>("");
  const [selectedParserText, setSelectedParserText] = useState("");

  const [getMapping, { data: InboxData }] = useLazyQuery(GET_MAPPING, {
    variables: {
      parserType: props.data.ParserType,
      templateId: selectedTemplate,
      emailAddress: props.data.EmailAddress,
    },
    fetchPolicy: "no-cache",
  });

  const [getParserTemplate] = useLazyQuery(GET_PARSER_TEMPLATE, {
    fetchPolicy: "no-cache",
  });

  const [updateEmailParserConfig] = useMutation(UPDATE_EMAIL_PARSER_CONFIG);

  const getForwardingValue = (data: any) => {
    let whenToForward = 1;
    if (data.DoNotForwardIfSentOK) whenToForward = 2;
    if (data.DoNotForwardIfDeliveredOK) whenToForward = 3;
    if (data.DoNotForwardIfReadOK) whenToForward = 4;
    if (data.DoNotForwardIfRepliedTo) whenToForward = 5;
    setDefaultForwardingValue(whenToForward);
  };

  const loadParserTypes = async () => {
    const orgs = await client.query({
      query: GET_PARSERTYPES,
      fetchPolicy: "no-cache",
    });
    const parserTypes = orgs?.data?.msgbox_EmailParserType || [];
    setParsers(parserTypes);
    for (let i = 0; i < parserTypes.length; i++) {
      if (parserTypes[i].Name === props.data.ParserType) {
        setSelectedParserText(parserTypes[i].Name.toLowerCase());
        setSelectedParser(i);
      }
    }
  };

  const loadTemplates = async (apiAccountId: number) => {
    const templates = await client.query({
      query: GET_TEMPLATES,
      variables: {
        apiAccountId: apiAccountId,
      },
      fetchPolicy: "no-cache",
    });
    const templateData = templates?.data?.msgbox_Template || [];

    setTemplates(templateData);
    setSelectedTemplate(props.data.templateId);
  };

  useEffect(() => {
    fetchData();
  }, [props]);

  const fetchData = async () => {
    if (!props.data.APIAccountId) return;

    loadParserTypes();
    loadTemplates(props.data.APIAccountId);
    setSelectedTemplate(props.data.templateId);
    getForwardingValue(props.data);
    setSelectedOrgName(props.data.OrgName);
    setSelectedForwardingEmail(props.data.ForwardingEmail);
    const mappingDataTemp = await getMapping();
    setMappingData(mappingDataTemp.data.msgbox_EmailParserConfig[0].Mapping);
  };

  useEffect(() => {
    if (templates.length > 1) {
      handleCustomMappings();
    }
  }, [templates, selectedTemplate, selectedParser]);

  useEffect(() => {
    if (addedCustomMaps.length > 0 || addedCustomMaps === null) {
      setCheckboxChecked(true);
    } else if (mappingData && mappingData.length > 0) {
      setCheckboxChecked(true);
    } else {
      setCheckboxChecked(false);
    }
  }, [mappingData, saved, addedCustomMaps]);

  const handleTemplateSelect = async (target: number) => {
    let res = await getParserTemplate({
      variables: {
        apiAccountId: props.data.APIAccountId,
        emailAddress: props.data.EmailAddress,
      },
    });

    let existingTemplateId =
      res.data.msgbox_EmailParserConfig[0].Template.TemplateId;
    if (existingTemplateId !== target) {
      setMappingData(null);
    }

    setSelectedTemplate(target);
  };

  const handleCustomMappings = async () => {
    const match: any = templates.find((template: any) => {
      return template.TemplateId === selectedTemplate;
    });

    const regex: RegExp = /\{\{(\d+)\}\}/g;
    let regXMatch: RegExpExecArray | null;
    const results: object[] = [];

    while ((regXMatch = regex.exec(match.TemplateText)) !== null) {
      results.push({ key: regXMatch[1], value: "" });
    }

    setTemplateVariableCount(results.length);

    setTemplateText(match.TemplateText);

    const optionsMatch = parserList.find(
      parser => Object.keys(parser)[0] === selectedParserText.toLowerCase()
    );

    const optionsArray: string[] = [];
    if (optionsMatch) {
      Object.entries(optionsMatch).forEach(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            if (typeof nestedValue === "string") {
              optionsArray.push(nestedValue);
            }
          });
        }
      });
    }

    setCustomMappingOptions(optionsArray);
  };

  const handleParserTypeSelect = async (target: any) => {
    let parserName = target.options[target.selectedIndex].text.toLowerCase();
    setSelectedParserText(parserName);
    setSelectedParser(target.selectedIndex);
  };

  async function handleSave(addedCustomMaps: any) {
    setSaved(true);

    let doNotForwardIfRepliedTo = false;
    let doNotForwardIfSentOK = false;
    let doNotForwardIfDeliveredOK = false;
    let doNotForwardIfReadOK = false;

    switch (selectedForwardOption) {
      case "1":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = false;
        break;
      case "2":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = true;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = false;
        break;
      case "3":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = true;
        doNotForwardIfReadOK = false;
        break;
      case "4":
        doNotForwardIfRepliedTo = false;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = true;
        break;
      case "5":
        doNotForwardIfRepliedTo = true;
        doNotForwardIfSentOK = false;
        doNotForwardIfDeliveredOK = false;
        doNotForwardIfReadOK = false;
        break;
    }

    const transformedArray = addedCustomMaps.map(
      (item: { mode: any; value: any }) => {
        return { [item.mode]: item.value };
      }
    );

    const feedback = await updateEmailParserConfig({
      variables: {
        apiAccountId: props.data.APIAccountId,
        emailAddress: props.data.EmailAddress,
        mapping: transformedArray.length === 0 ? null : transformedArray,
        parserType: parsers[selectedParser].Name,
        templateId: selectedTemplate,
        forwardingEmail: selectedForwardingEmail,
        doNotForwardIfRepliedTo,
        doNotForwardIfSentOK,
        doNotForwardIfDeliveredOK,
        doNotForwardIfReadOK,
      },
    });

    if (
      feedback?.data?.update_msgbox_EmailParserConfig?.returning?.length > 0
    ) {
      toast.success("Added Successfully!");
    } else {
      toast.error("Failed to add data.");
    }

    setEditCustomMappingsModal(false);
    props.setModal(false);
  }

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal isOpen={props.modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Edit Email Parser
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm>
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <FormGroup className="mb-3">
                  <Label>Organisation</Label>
                  <Input
                    className="avInput"
                    id="selectOrg"
                    name="selectOrg"
                    type="text"
                    value={selectedOrgName ?? ""}
                    readOnly
                    style={{ color: "#6c757d", cursor: "not-allowed" }}
                  />
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label>API Account</Label>
                  <Input
                    className="avInput"
                    id="selectApi"
                    name="selectApi"
                    type="text"
                    value={props.data.APIAccountName ?? ""}
                    readOnly
                    style={{ color: "#6c757d", cursor: "not-allowed" }}
                  />
                </FormGroup>

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="parserEmailAddress"
                    label="Incoming Email Address"
                    type="text"
                    value={props.data.EmailAddress ?? ""}
                    readOnly
                    style={{ color: "#6c757d", cursor: "not-allowed" }}
                  />
                </div>

                <FormGroup className="mb-3">
                  <Label>Select Template</Label>
                  <Input
                    className="avInput"
                    id="template"
                    name="template"
                    type="select"
                    value={selectedTemplate ?? ""}
                    onChange={e =>
                      handleTemplateSelect(toNumber(e.currentTarget.value))
                    }
                  >
                    <option value="" disabled>
                      {templates.length > 0
                        ? "Select a template"
                        : "No Templates Found"}
                    </option>
                    {Array.isArray(templates) &&
                      templates.map((order, key) => (
                        <option key={key} value={order["TemplateId"]}>
                          {order["Name"]}
                        </option>
                      ))}
                  </Input>
                </FormGroup>

                <FormGroup
                  className="mb-3 flex flex-row"
                  style={{ display: "flex" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "70%",
                    }}
                  >
                    <Label>Select Parser Type</Label>
                    <Input
                      className="avInput"
                      id="parserType"
                      name="parserType"
                      type="select"
                      value={selectedParser ?? ""}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleParserTypeSelect(e.currentTarget);
                      }}
                    >
                      {Array.isArray(parsers) && parsers.length > 0 ? (
                        parsers.map((order, key) => (
                          <option key={key} value={key}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No Parser Types Found</p>
                      )}
                    </Input>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "30%",
                      alignItems: "center",
                    }}
                  >
                    <Label>Enable Custom Mappings</Label>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Input
                        type="checkbox"
                        style={{ width: 30, height: 30 }}
                        onChange={() => {
                          if (!checkboxChecked) {
                            setEditCustomMappingsModal(true);
                            setCheckboxChecked(true);
                          } else {
                            setCheckboxChecked(false);
                          }
                        }}
                        checked={checkboxChecked}
                      />
                      {checkboxChecked && (
                        <p
                          onClick={() => {
                            setEditClicked(true);
                            setEditCustomMappingsModal(true);
                          }}
                          style={{ padding: 8, cursor: "pointer" }}
                        >
                          Edit
                        </p>
                      )}
                    </div>
                  </div>
                </FormGroup>

                <div className="mb-3">
                  <Label>Forwarding Email Address</Label>
                  <Input
                    className="avInput"
                    name="forwardEmailAddress"
                    type="email"
                    placeholder="Forwarding email address"
                    value={selectedForwardingEmail ?? ""}
                    onChange={e => setSelectedForwardingEmail(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <Label>Forwarding Option</Label>
                  <Input
                    className="avInput"
                    type="select"
                    id="forwardOption"
                    value={selectedForwardOption ?? "1"}
                    onChange={e => setSelectedForwardOption(e.target.value)}
                  >
                    <option value="1">Always forward</option>
                    <option value="2">Dont forward if successfully sent</option>
                    <option value="3">Dont forward if delivered</option>
                    <option value="4">Dont forward if customer read</option>
                    <option value="5">Dont forward if customer replied</option>
                  </Input>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-success save-user"
                    onClick={() => handleSave(addedCustomMaps)}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        style={{ marginTop: 200, zIndex: 1000 }}
        isOpen={editCustomMappingsModal}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <ModalBody>
            <ModalHeader tag="h4">Email Parser Config</ModalHeader>
            <p style={{ margin: 15 }}>
              Add custom mappings for your email parser below:
            </p>

            <CustomMappingMenu
              addedCustomMaps={addedCustomMaps}
              setAddedCustomMaps={setAddedCustomMaps}
              customMappingOptions={customMappingOptions}
              templateText={templateText}
              templateVariableCount={templateVariableCount}
              mapData={mappingData}
              editClicked={editClicked}
            ></CustomMappingMenu>
          </ModalBody>

          <div
            style={{
              position: "relative",
              bottom: 10,
              display: "flex",
              justifyContent: "end",
              margin: 15,
            }}
          >
            <button
              className="btn btn-success save-user m-2"
              onClick={() => {
                setSaved(true),
                  setEditCustomMappingsModal(!editCustomMappingsModal);
              }}
              style={{ width: "80px", height: "40px" }}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default EditEmailParser;
